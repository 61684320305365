<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('order.orderNo')">
              <a-input v-model="orderNo" />
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
              <a-button type="danger" @click="specialPayChannelOrderDeleteMethod">{{ $t('delete') }}</a-button>

            </span>
          </a-col>
        </a-row>
      </a-form>

      <!-- <a-table
          key="id"
          ref="table"
          size="middle"
          rowKey="taskId"
          :columns="columns"
          :dataSource="dataSource"
          :pagination="false"
          :loading="loading"
          bordered
        >

          
          <span slot="action" slot-scope="text, record">
           
            <a-button type="primary" size="small" @click="zdAmount(record)" style="margin: 10px"
              >{{$t('delete')}}</a-button>
          </span>
        </a-table> -->
      <!-- <div style="margin: 10px 0px; display: flex; justify-content: flex-end">
          <v-pagination
            v-if="total"
            :page-num="form.pageNum"
            :page-size="form.pageSize"
            :total="total"
            @change="handleTableChange"
          />
        </div> -->
    </div>
  </div>
</template>

<script>

import moment from 'moment'
import { specialPayChannelOrderDelete } from '@/webpublicapi/specialPayChannelOrder'
const I18N_KEY = {
  ORDER: 'order.',
}
export default {
  name: 'specialPayChannelOrder',
  data() {
    return {
      I18N_KEY,
      orderNo: "",
      // form: {
      //   pageNum: 1,
      //   pageSize: 10,
      // },
      // loading: false,
      // dataSource:[],
      // total:0,
      // queryParam:{
      //     orderNo:"",
      // },
      // dataSource:[]

    }
  },
  computed: {

    columns() {
      return [
        {
          title: "orderId",
          align: 'left',
          dataIndex: 'id',
        },

      ]
    },
  },
  created() {

  },
  methods: {
    specialPayChannelOrderDeleteMethod() {
      this.loading = true
      specialPayChannelOrderDelete({ orderNo: this.orderNo }).then(res => {
        if (res.code === 1000) {
          this.$message.success('Delete Successful !')
        } else {

          this.$message.error(res.msg)
        }
      })
    }
    // searchQuery() {
    //   this.loadData()
    // },
    // async loadData(data) {

    //   
    //   await repayCertWorkOrderList(params)
    //     .then((res) => {
    //       this.total = res.data.total
    //       this.dataSource = res.data.rows
    //     })
    //     .catch((err) => {
    //       console.log(err)
    //       this.dataSource = []
    //       this.total = ''
    //     })
    //   this.loading = false
    // },
    // transformTime(time) {
    //   return moment(time).format('YYYY-MM-DD')
    // },
    // transformTime2(time) {
    //   return moment(time).format('YYYY-MM-DD HH:mm:ss')
    // },
    // transformStringToLow(str) {
    //   return str && str.toLocaleLowerCase()
    // },


    // // 分页
    // handleTableChange(pageNum, pageSize) {
    //   this.form.pageNum = pageNum
    //   this.form.pageSize = pageSize
    //   let data = { ...this.form, ...this.queryParam }
    //   this.loadData()
    // },
    // // 重置字典类型搜索框的内容

    // copy(e) {
    //   //复制方法
    //   let input = document.createElement('input')
    //   input.value = e
    //   document.body.appendChild(input)
    //   input.select()
    //   document.execCommand('copy')
    //   document.body.removeChild(input)
    //   this.$message.success('复制成功')
    //   this.visibleUrl = false
    // },

  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/common.less';

.description {
  line-height: 1.4;

  &.d_text {
    text-indent: 1em;
  }

  &.d_high_light {
    color: #fd9a38;
  }
}

.active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image {
  width: 100px;
  height: 100px;
}
</style>