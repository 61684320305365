<template>
  <div class="approvalDetail" style="position: relative">
    <a-spin :spinning="loading">
      <a-card
        style="margin-top: 24px"
        :title="$t(I18N_KEY.CUSTOMER + 'customerImageInfo')"
        type="inner"
        v-viewer="viewOptions"
      >
        <div v-for="(item, index) in imageInfos" :key="item.imgType">
          <div class="file-box">
            <div class="file-item">
              <p class="file-label">{{ FILE_TYPE[item.imgType] }}</p>
              <img class="file-preview" :src="item.imgUrl" preview="0" />
            </div>
          </div>
        </div>
      </a-card>
      <!--<a-card-->
      <!--style="margin-top: 24px"-->
      <!--:title="$t(I18N_KEY.CUSTOMER + 'excelList')"-->
      <!--type="inner"-->
      <!--v-viewer="viewOptions">-->
      <!--<div v-for="(item, index) in excelList" :key="item.imgType">-->
      <!--<div class="file-box">-->
      <!--<div class="file-item">-->
      <!--<p class="file-label">{{ FILE_TYPE[item.imgType] }}</p>-->
      <!--<img class="file-preview" :src="item.url" preview="0">-->
      <!--</div>-->
      <!--</div>-->
      <!--</div>-->
      <!--</a-card>-->
      <!--<a-card-->
      <!--style="margin-top: 24px"-->
      <!--:title="$t(I18N_KEY.CUSTOMER + 'orderImages')"-->
      <!--type="inner"-->
      <!--v-viewer="viewOptions">-->
      <!--<div v-for="(item, index) in orderImages" :key="item.imgType">-->
      <!--<div class="file-box">-->
      <!--<div class="file-item">-->
      <!--<p class="file-label">{{ FILE_TYPE[item.imgType] }}</p>-->
      <!--<img class="file-preview" :src="item.imgUrl" preview="0">-->
      <!--</div>-->
      <!--</div>-->
      <!--</div>-->
      <!--</a-card>-->
      <a-card :title="$t(I18N_KEY.ORDER + 'orderInfo')" type="inner" style="margin-top: 24px">
        <detail-list>
          <detail-list-item :term="$t(I18N_KEY.ORDER + 'productName')">{{ orderInfo.productName }}</detail-list-item>
          <detail-list-item :term="$t(I18N_KEY.ORDER + 'orderNo')">{{ orderInfo.orderNo }}</detail-list-item>
          <detail-list-item :term="$t(I18N_KEY.ORDER + 'orderType')">{{
            $t(ORDER_TYPE[orderInfo.orderType])
          }}</detail-list-item>
          <detail-list-item :term="$t(I18N_KEY.ORDER + 'applyAmt')">{{ orderInfo.applyAmt }}</detail-list-item>
          <detail-list-item :term="$t(I18N_KEY.ORDER + 'paymentAmt')">{{ orderInfo.paymentAmt }}</detail-list-item>
          <detail-list-item :term="$t(I18N_KEY.ORDER + 'paymentTime')">{{ orderInfo.paymentTime }}</detail-list-item>
          <!--                  <detail-list-item :term="$t(I18N_KEY.ORDER + 'extendAmoumt')">{{orderInfo.extendAmoumt}}</detail-list-item>-->
          <!--                  <detail-list-item :term="$t(I18N_KEY.ORDER + 'repaymentAmount')">{{orderInfo.repaymentAmount}}</detail-list-item>-->
          <!--                  <detail-list-item :term="$t(I18N_KEY.ORDER + 'settleAmt')">{{orderInfo.settleAmt}}</detail-list-item>-->
          <!--                  <detail-list-item :term="$t(I18N_KEY.ORDER + 'lateFee')">{{orderInfo.lateFee }}</detail-list-item>-->
          <!--                  <detail-list-item :term="$t(I18N_KEY.ORDER + 'overdueDayC')">{{orderInfo.overdueDays }}</detail-list-item>-->
          <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'createTime')">{{ orderInfo.submitTime }}</detail-list-item>
        </detail-list>
      </a-card>
      <a-card :title="$t(I18N_KEY.ORDER + 'examineCustomerInfo')" type="inner" style="margin-top: 24px">
        <detail-list>
          <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'mobile')"
            ><a :href="'sip:' + callInfo">{{ basicInfo.mobileNo }}</a></detail-list-item
          >
          <detail-list-item :term="$t('email')">{{ basicInfo.email }}</detail-list-item>
          <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'age')">{{ basicInfo.age }}</detail-list-item>
          <detail-list-item :term="$t('sex')">{{ SEX[basicInfo.sex] }}</detail-list-item>
          <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'fullName')">{{ basicInfo.fullName }}</detail-list-item>
          <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'bankName')">{{
            bankCardInfo.bankAccountName
          }}</detail-list-item>
          <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'bankAccountNo')">{{
            bankCardInfo.bankAccountNo
          }}</detail-list-item>
          <detail-list-item :term="$t(I18N_KEY.CUSTOMER + 'residentialAddress')">{{
            detailInfo.residentialAddress
          }}</detail-list-item>
          <!--                  <detail-list-item > <a :href="'sip:'+ this.callInfo">{{ this.collectPhone }}</a></detail-list-item>-->
        </detail-list>
      </a-card>
      <a-card :title="$t(I18N_KEY.ORDER + 'examineAddressInfo')" type="inner" style="margin-top: 24px">
        <a-card style="margin-top: 24px" title="Repay Plan" type="inner">
          <a-table
            :columns="planColumns"
            :dataSource="plans"
            :pagination="false"
            bordered
            ref="table"
            size="middle"
            :rowKey="(record) => record.index"
          >
            <Dict slot="status" slot-scope="text" :dict-code="DICT_CODE.SETTLE_STATUS" :value="text" />
            <!-- <span slot="status" slot-scope="text, record">
              ddd
            </span> -->
            <!--  -->
          </a-table>
        </a-card>
        <a-card style="margin-top: 24px" :title="$t(I18N_KEY.CUSTOMER + 'customerLinkInfo')" type="inner">
          <a-table
            :columns="linkColumns"
            :dataSource="linkmanInfos"
            :pagination="false"
            bordered
            ref="table"
            size="middle"
            :rowKey="(record) => record.index"
          >
            <span slot="contactType" slot-scope="text" :value="text">{{ $t(CONTACT_TYPE[text]) }}</span>
            <Dict slot="relationType" slot-scope="text" :dict-code="DICT_CODE.REALTIONSHIP" :value="text" />
            <a slot="contactPhone" slot-scope="text" :href="'sip:' + text + ',orderId' + taskId">{{ text }}</a>
          </a-table>
        </a-card>
        <a-card type="inner">
          <a-tabs v-model="currentTab">
            <a-tab-pane :tab="$t(I18N_KEY.ORDER + 'collectionRecord')" key="1" forceRender>
              <a-table
                :columns="examineColumns"
                :dataSource="examineSource"
                :pagination="false"
                bordered
                ref="table"
                size="middle"
              >
                <Dict
                  slot="custContractCondition"
                  slot-scope="text"
                  :dict-code="DICT_CODE.CUST_CONTRACT_CONDITION"
                  :value="text"
                />
                <Dict slot="linkCondition" slot-scope="text" :dict-code="DICT_CODE.LINKCONDITION" :value="text" />
                <Dict
                  slot="repaymentIntention"
                  slot-scope="text"
                  :dict-code="DICT_CODE.REAPYMENT_INTENTION"
                  :value="text"
                />
                <Dict
                  slot="collectionTaskStatus"
                  slot-scope="text"
                  :dict-code="DICT_CODE.COLLECTION_TASK_STATUS"
                  :value="text"
                />
              </a-table>
            </a-tab-pane>
            <a-tab-pane :tab="$t(I18N_KEY.ORDER + 'orderNumber')" key="5" forceRender>
              <a-table
                :columns="orderNumberColumns"
                :dataSource="orderNumberSource"
                :pagination="false"
                bordered
                ref="table"
                size="middle"
              >
              </a-table>
            </a-tab-pane>
            <a-tab-pane :tab="$t(I18N_KEY.ORDER + 'addressBookList')" key="2">
              <a-table
                :columns="addressBookColumns"
                :dataSource="addressBookSource"
                :pagination="addressBookIpagination"
                :loading="addressBookLoading"
                @change="handleTableChange"
                bordered
                ref="table"
                size="middle"
              >
              </a-table>
            </a-tab-pane>
            <a-tab-pane :tab="$t(I18N_KEY.ORDER + 'repaymentRecord')" key="4">
              <a-table
                :columns="repayRecordColumns"
                :dataSource="repayRecordSource"
                :pagination="false"
                bordered
                ref="table"
                size="middle"
                :rowKey="(record) => record.index"
              >
              </a-table>
            </a-tab-pane>
          </a-tabs>
        </a-card>
      </a-card>
    </a-spin>
    <div
      v-if="auditDrawer && !loading"
      :class="{ 'setting-drawer-handle': true, active: visible }"
      @click="handelDrawer(!visible)"
    >
      <a-icon type="double-left" v-if="!visible" />
      <a-icon type="double-right" v-if="visible" />
    </div>
    <a-drawer
      v-if="auditDrawer"
      width="600"
      placement="right"
      :title="$t(I18N_KEY.COLLECTION + 'approval')"
      :visible="visible"
      :maskClosable="false"
      :getContainer="drawContainer"
      :wrapStyle="{ height: 'calc(100% - 20px)', overflow: 'auto', paddingBottom: '20px' }"
      @close="closeDrawer"
    >
      <div class="audit-form">
        <a-form :form="basicForm" layout="vertical">
          <a-form-item :label="$t(I18N_KEY.COLLECTION + 'followResult')">
            <dict-radio-group
              v-decorator="['followResult', validatorRules.followResult]"
              :dict-code="DICT_CODE.CUST_CONTRACT_CONDITION"
            />
          </a-form-item>
          <a-form-item :label="$t(I18N_KEY.COLLECTION + 'linkCondition')">
            <dict-radio-group v-decorator="['linkCondition']" :dict-code="DICT_CODE.LINKCONDITION" />
          </a-form-item>
          <a-form-item :label="$t(I18N_KEY.COLLECTION + 'followTime')">
            <a-date-picker
              v-decorator="['followTime', validatorRules.followTime]"
              showTime
              format="YYYY-MM-DD HH:mm:ss"
            />
          </a-form-item>
          <a-form-item :label="$t(I18N_KEY.ORDER + 'approvalRemark')">
            <a-textarea v-decorator="['remark']" :rows="4" />
          </a-form-item>
        </a-form>
      </div>
      <div class="footer">
        <a-button style="marginright: 8px" @click="closeDrawer">
          {{ $t('cancel') }}
        </a-button>
        <a-button :loading="confirmLoading" @click="submitAuditForm" type="primary">
          {{ $t('confirm') }}
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>
<script>
import { filterObj } from '@/utils/util'
import { DetailList } from '@/components'
import { mapActions, mapGetters } from 'vuex'
import { REBORROW_FLAG, SEX, CONTACT_TYPE, ORDER_TYPE } from '@/constants/status'
import Dict from '../modules/Dict'
import DictRadioGroup from '../modules/DictRadioGroup'
import imageMixin from '@/mixins/image-mixin'
import moment from 'moment'
import exportFile from '@/webpublicapi/exportFile'
const DetailListItem = DetailList.Item
const I18N_KEY = {
  CUSTOMER: 'customer.',
  ORDER: 'order.',
  APPROVAL: 'approval.',
  COLLECTION: 'collection.',
}

export default {
  props: {
    orderId: {
      type: [String, Number],
      default: undefined,
    },
    custInfoId: {
      type: [String, Number],
      default: undefined,
    },
    taskId: {
      type: [String, Number],
      default: undefined,
    },
    auditDrawer: {
      type: Boolean,
      default: false,
    },
    callInfo: {
      type: String,
      default: undefined,
    },
    collectPhone: {
      type: String,
      default: undefined,
    },
  },
  components: {
    DetailList,
    DetailListItem,
    Dict,
    DictRadioGroup,
  },
  mixins: [imageMixin],
  data() {
    return {
      I18N_KEY,
      REBORROW_FLAG,
      SEX,
      CONTACT_TYPE,
      ORDER_TYPE,
      visible: false,
      loading: true,
      confirmLoading: false,
      basicForm: this.$form.createForm(this),
      listItem: {},
      openDrawerCount: 0,
      currentTab: '1',
      plans: [],
      orderInfo: {},
      planColumns: [
        {
          title: '期数',
          align: 'left',
          dataIndex: 'period',
        },
        {
          title: '开始日',
          align: 'left',
          dataIndex: 'beginDate',
        },
        {
          title: '结束日',
          align: 'left',
          dataIndex: 'endDate',
        },
        {
          title: '本金',
          align: 'left',
          dataIndex: 'capital',
        },
        {
          title: '已还本金',
          align: 'left',
          dataIndex: 'settledCapital',
        },
        {
          title: '利息',
          align: 'left',
          dataIndex: 'interest',
        },
        {
          title: '服务费',
          align: 'left',
          dataIndex: 'serviceFee',
        },
        {
          title: '滞纳金',
          align: 'left',
          dataIndex: 'lateFee',
        },
        {
          title: '其他费用',
          align: 'left',
          dataIndex: 'otherFee',
        },
        {
          title: '抵扣费用',
          align: 'left',
          dataIndex: 'deductionFee',
        },
        {
          title: '状态',
          align: 'left',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
        },
      ],
      linkColumns: [
        {
          title: this.$t(I18N_KEY.CUSTOMER + 'contactType'),
          dataIndex: 'contactType',
          key: 'contactType',
          align: 'center',
          scopedSlots: { customRender: 'contactType' },
        },
        {
          title: this.$t(I18N_KEY.CUSTOMER + 'contactRelationship'),
          dataIndex: 'relationType',
          key: 'relationType',
          align: 'center',
          scopedSlots: { customRender: 'relationType' },
        },
        {
          title: this.$t(I18N_KEY.CUSTOMER + 'contactName'),
          dataIndex: 'contactName',
          key: 'contactName',
          align: 'center',
        },
        {
          title: this.$t(I18N_KEY.CUSTOMER + 'contactPhone'),
          dataIndex: 'contactPhone',
          scopedSlots: { customRender: 'contactPhone' },
          key: 'contactPhone',
          align: 'center',
        },
      ],
      examineColumns: [
        {
          title: this.$t(I18N_KEY.ORDER + 'overdueDay'),
          dataIndex: 'overdueDay',
          align: 'center',
        },
        {
          title: this.$t(I18N_KEY.ORDER + 'followResult'),
          dataIndex: 'followResult',
          align: 'center',
          scopedSlots: { customRender: 'custContractCondition' },
        },
        {
          title: this.$t(I18N_KEY.ORDER + 'linkCondition'),
          dataIndex: 'contactsSituation',
          align: 'center',
          scopedSlots: { customRender: 'linkCondition' },
        },
        {
          title: this.$t(I18N_KEY.ORDER + 'collectionRemark'),
          dataIndex: 'remark',
          align: 'center',
        },
        {
          title: this.$t(I18N_KEY.ORDER + 'followUserName'),
          dataIndex: 'followUserName',
          align: 'center',
        },
        {
          title: this.$t(I18N_KEY.ORDER + 'followTime'),
          dataIndex: 'followTime',
          align: 'center',
        },
        {
          title: this.$t(I18N_KEY.ORDER + 'collectionTaskStatus'),
          dataIndex: 'collectionTaskStatus',
          align: 'center',
          scopedSlots: { customRender: 'collectionTaskStatus' },
        },
      ],
      orderNumberColumns: [
        {
          title: this.$t(I18N_KEY.CUSTOMER + 'mobile'),
          dataIndex: 'phoneNo',
          align: 'center',
        },
      ],
      addressBookSource: [],
      // 通讯录分页参数
      addressBookIpagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0,
      },
      addressBookLoading: false,
      addressBookColumns: [
        {
          title: this.$t(I18N_KEY.CUSTOMER + 'contactName'),
          dataIndex: 'name',
          align: 'center',
        },
        {
          title: this.$t(I18N_KEY.CUSTOMER + 'contactPhone'),
          dataIndex: 'phoneNo',
          align: 'center',
        },
      ],
      repayRecordColumns: [
        {
          title: this.$t(I18N_KEY.ORDER + 'payOrderNo'),
          dataIndex: 'payOrderNo',
          align: 'center',
        },
        {
          title: this.$t(I18N_KEY.ORDER + 'payType'),
          dataIndex: 'payType',
          align: 'center',
        },
        {
          title: this.$t(I18N_KEY.ORDER + 'payAmount'),
          dataIndex: 'payAmount',
          align: 'center',
        },
        {
          title: this.$t(I18N_KEY.ORDER + 'payRecipt'),
          dataIndex: 'payRecipt',
          align: 'center',
        },
        {
          title: this.$t(I18N_KEY.ORDER + 'description'),
          dataIndex: 'description',
          align: 'center',
        },
        {
          title: this.$t(I18N_KEY.ORDER + 'errorDescription'),
          dataIndex: 'errorDescription',
          align: 'center',
        },
        {
          title: this.$t(I18N_KEY.ORDER + 'recordTime'),
          dataIndex: 'recordTime',
          align: 'center',
        },
      ],
      FILE_TYPE: {
        aadhaar_front: this.$t(I18N_KEY.CUSTOMER + 'aadhaarFront'),
        aadhaar_back: this.$t(I18N_KEY.CUSTOMER + 'aadhaarBack'),
        pan_front: this.$t(I18N_KEY.CUSTOMER + 'panFront'),
        face_photo_list: this.$t(I18N_KEY.CUSTOMER + 'facePhotoList'),
      },
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 20,
      },
    }
  },
  computed: {
    ...mapGetters('order', [
      'orderDetail',
      'orderDetailForCollection',
      'collectionLogList',
      'addressBookList',
      'repayRecordList',
      'orderNumberList',
      'orderImageList',
    ]),
    ...mapGetters('customer', ['customerDetail']),
    basicInfo() {
      return (this.customerDetail && this.customerDetail.basicInfo) || {}
    },
    detailInfo() {
      return (this.customerDetail && this.customerDetail.detailInfo) || {}
    },
    linkmanInfos() {
      return (this.customerDetail && this.customerDetail.linkmanInfos) || []
    },
    aadhaarInfo() {
      return (this.customerDetail && this.customerDetail.aadhaarInfo) || {}
    },
    panInfo() {
      return (this.customerDetail && this.customerDetail.panInfo) || {}
    },
    imageInfos() {
      return (this.customerDetail && this.customerDetail.imageInfos) || []
    },
    orderImages() {
      return (this.orderImageList && this.orderImageList.orderImageInfoList) || []
    },
    excelList() {
      return (this.orderImageList && this.orderImageList.excelList) || []
    },
    bankCardInfo() {
      return (this.customerDetail && this.customerDetail.bankCardInfo) || []
    },
    // orderInfo() {
    //   return (this.orderDetailForCollection || {}).orderInfo || {}
    // },
    examineSource() {
      return this.collectionLogList || []
    },
    orderNumberSource() {
      return this.orderNumberList || []
    },
    repayRecordSource() {
      return this.repayRecordList || []
    },
    validatorRules() {
      return {
        followResult: {
          rules: [
            {
              required: true,
              message: this.$t('select') + this.transformStringToLow(this.$t(I18N_KEY.COLLECTION + 'followResult')),
            },
          ],
        },
        // repaymentIntention:{
        //     rules: [
        //         { required: true, message: this.$t('select') + this.transformStringToLow(this.$t(I18N_KEY.COLLECTION + 'linkCondition')) },
        //     ]
        // },
        followTime: {
          rules: [{ required: true, message: this.$t('select') + this.transformStringToLow(this.$t('time')) }],
        },
      }
    },
  },
  mounted() {
    window.aa = this
    this.requestDetail(this.orderId)
  },
  methods: {
    ...mapActions('order', [
      'getOrderDetail',
      'getOrderDetailForCollection',
      'getCollectionLogList',
      'getAddressBookList',
      'getRepayRecordList',
      'getOrderNumberList',
      'getOrderImageList',
    ]),
    ...mapActions('approval', ['getApprovalListItem']),
    ...mapActions('platformCollection', ['followCollection']),
    ...mapActions('customer', ['getCustomerDetail']),
    transformStringToLow(str) {
      return str && str.toLocaleLowerCase()
    },
    drawContainer() {
      return document.querySelector('.ant-modal-wrap')
    },
    handelDrawer(visible) {
      if (visible) {
        this.openDrawerCount++
        this.openDrawer()
      } else {
        this.closeDrawer()
      }
    },
    openDrawer() {
      this.visible = true
    },
    closeDrawer() {
      this.visible = false
      // this.basicForm.resetFields();
    },
    transformTime(time, format) {
      return moment(time).format(format)
    },
    submitAuditForm(status) {
      this.basicForm.validateFields((err, values) => {
        if (!err) {
          this.confirmLoading = true
          const { remark, followResult, linkCondition, followTime } = values
          delete values.remark
          const data = {
            taskId: this.taskId,
            followResult,
            linkCondition,
            followTime: this.transformTime(followTime, 'YYYY-MM-DD HH:mm:ss'),
            remark,
          }
          console.log(99, data)
          this.followCollection(data)
            .then((res) => {
              this.$message.success(this.$t('success'))
              this.closeParentModal()
            })
            .catch((e) => {
              this.$message.error(e.message)
            })
            .finally(() => {
              this.confirmLoading = false
            })
        }
      })
    },
    closeParentModal() {
      this.$emit('close')
    },
    loadData(arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.addressBookIpagination.current = 1
      }
      this.addressBookLoading = true
      var params = this.getQueryParams() // 查询条件
      this.getAddressBookList(Object.assign({}, params))
        .then((res) => {
          if (res.code === 1000) {
            this.addressBookIpagination.total = res.data.total
            this.addressBookSource = res.data.rows
          } else {
            this.addressBookIpagination.total = 0
            this.addressBookSource = []
            this.$message.error(res.msg)
          }
          this.addressBookLoading = false
        })
        .catch((e) => {
          this.addressBookLoading = false
          this.$message.error(e.message)
        })
    },
    getQueryParams() {
      var param = {}
      param.custId = this.custInfoId
      param.pageNum = this.addressBookIpagination.current
      param.pageSize = this.addressBookIpagination.pageSize
      return filterObj(param)
    },
    handleTableChange(pagination) {
      this.addressBookIpagination = pagination
      this.loadData()
    },
    requestDetail(id) {
      this.getCollectionLogList({ orderId: id })
      this.getRepayRecordList({ orderId: id })
      this.getOrderNumberList({ orderInfoId: id })

      this.loadData(1)
      Promise.all([
        this.getOrderDetail({ id: this.orderId }),
        // this.getOrderDetailForCollection({ id }),
        this.getCustomerDetail({ id: this.custInfoId }),
        // this.getOrderImageList({ orderInfoId: id, taskId: this.taskId }),
      ])
        .then((res) => {
          console.log('------------------')
          console.log(res)
          const result = res[0]

          if (result.code === 1000) {
            this.orderInfo = result.data.orderInfo
            this.plans = result.data.plans
          }
          this.loading = false
        })
        .catch((e) => {
          this.$message.error(e.message)
          this.loading = false
        })
    },
    downLoadData() {
      const file = {
        fileUrl: '/api/web/orderImage/selectByOrderInfoId?taskId=' + this.taskId,
        fileName: 'collectionletter.png',
      }
      this.loading = true
      exportFile(file)
        .catch((e) => {
          this.$message.error(e.message)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style lang="less" scoped>
/deep/ .description-list .content {
  font-weight: 700;
}
.file-box {
  float: left;
  width: 200px;
  height: 200px;
  margin-right: 10px;
  margin: 0 8px 8px 0;

  .file-item {
    width: 100%;
    height: 100%;
    position: relative;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    .file-preview {
      padding: 8px;
      width: 182px;
      height: 142px;
    }

    .file-label {
      margin: 0;
      text-align: center;
      border-bottom: 1px solid #d9d9d9;
      padding: 8px;
      font-weight: 500;
      font-size: 16px;
    }
  }
}

.setting-drawer-handle {
  position: fixed;
  top: 240px;
  background: #1890ff;
  width: 32px;
  height: 32px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  pointer-events: auto;
  z-index: 1301;
  text-align: center;
  font-size: 16px;
  border-radius: 4px 0 0 4px;
  transition: transform 0.3s cubic-bezier(0.9, 0, 0.3, 0.7);

  &.active {
    transform: translateX(-600px);
    // right: 500px;
  }

  i {
    color: rgb(255, 255, 255);
    font-size: 20px;
  }
}

.audit-form {
  width: 100%;
  padding: 10px;
  border: 1px solid #e9e9e9;
  background: #fff;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
}
</style>
