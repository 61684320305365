<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
              <a-row :gutter="24">
                <a-col :md="6" :sm="8">
                  <a-form-item :label="'模版名'">
                    <a-input
                        :placeholder="$t('enter') + ' 模版名' "
                        v-model="queryParam.name"/>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="8">
                  <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
                    <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
                     <a-button type='primary' @click="viewCoupon('add')"  style="margin-left: 8px">新增</a-button>
                  </span>
                </a-col>
              </a-row>
            </a-form>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered
        @change="handleTableChange">
        <span slot="statusType" slot-scope="text" :value="text" >{{ $t(REPAY_STATUS[text]) }}</span>
        <span slot="payType" slot-scope="text" :value="text" >{{ $t(PAYTYPE[text]) }}</span>
        <span slot='action' slot-scope='text, record'>
          <a @click="viewCoupon('edit', record)">
            {{ '编辑' }}
          </a>
        </span>
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
          <v-pagination
              v-if="total"
              :page-num="form.pageNum"
              :page-size="form.pageSize"
              :total="total"
              @change="handleTableChange" />

      </div>
    </div>
    <a-modal
       @ok='submitForm'
       v-model='visible'>
      <a-form :form='basicForm' :label-col='{ span: 6 }' :wrapper-col='{ span: 18 }'>
        <a-form-item :label="'盘号'">
          <a-select
            mode="multiple"
            v-model='basicForm.appSsid'
          >
            <a-select-option value='---'>
              {{ 'ALL' }}
            </a-select-option>
            <a-select-option v-for='item in appList' :key='item.appssid' :value='item.appssid'>
              {{ item.appName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="'状态'">
          <a-select
            v-model='basicForm.status' @change="handleTypeChange"
          >
            <a-select-option value='1' selected>
              启用
            </a-select-option>
            <a-select-option value='0'>
              关闭
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="'系统模版名'">
          <a-input v-model='basicForm.name' />
        </a-form-item>
        <a-form-item :label="'sender id'">
          <a-input v-model='basicForm.senderId' />
        </a-form-item>
        <a-form-item :label="'whatsapp模版名'">
          <a-input v-model='basicForm.templateName' />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { PAYTYPE, REPAY_STATUS } from '@/constants/status'
import { addWhatsappInfo, getApplist } from '@/webpublicapi/order'
import {
  getPlatformLoanOrderList,
  paymentsLink,
  reductionLateFee,
  repayOfflineAll,
  extendOffline,
  getRepayOrderList, getWhatsappList
} from '@/webpublicapi/order'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import moment from 'moment'
import vModal from '../modules/Modal';
import OrderDetail from '../order/OrderDetail';
import md5 from 'md5'
import { addOrEditSmsTask } from '@/webpublicapi/operation'
const I18N_KEY = 'order.';

export default {
  name: 'LoanOrderlist',
  components: {
    Dict,
    DictSelect,
    vModal,
    OrderDetail,
  },
  data () {
    return {
      I18N_KEY,
      REPAY_STATUS,
      PAYTYPE,
      toggleSearchStatus:false,
      visible: false,
      visibleO: false,
      ModalText: '',
      orderId: undefined,
      custInfoId: undefined,
      // 分页参数
      dataSource: [],
      appList: [],
      basicForm: {},
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      // 查询条件
      queryParam: {
        "orderNo":"",
        "orderId":undefined,
        "mobileNo":"",
        "aadhaarName":"",
        "applyDate":undefined,
        "paymentDate":undefined,
        "settleDate":undefined,
        "endDate":undefined,
        "email":""
      } ,
      defaultBasicForm: {
        id: '',
        name: '',
        templateName: '',
        appSsid: '',
        senderId: ''
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title: '盘号',
          align: 'left',
          width: '50px',
          dataIndex: 'appSsid'
        },

        {
          title: 'sender_id',
          align: 'left',
          width: '100px',
          dataIndex: 'senderId'
        },

        {
          title: '系统模版名',
          align: 'left',
          width: '200px',
          dataIndex: 'name'
        },
        {
          title: 'whatsapp模版名',
          align: 'left',
          width: '200px',
          dataIndex: 'templateName'
        },
        {
          title: '状态',
          align: 'left',
          width: '30px',
          dataIndex: 'status',
          customRender: function (text) {
            if (text==='0') {
              return "关闭"
            } else if (text === '1') {
              return "启用"
            }
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: '70px',
          scopedSlots: { customRender: 'action' }
        }
      ]
    },
  },
  created () {
    getApplist().then(res => {
      this.appList = []
      this.appInList = res.data || []
      for (var i = 0; i < this.appInList.length; i++) {
        if (this.appInList[i].appssid != '') {
          this.appList.push(this.appInList[i])
        }
      }
    })
    // this.loadData(1)
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData(1)
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }

      this.loading = true
      var params = this.getQueryParams()// 查询条件
      getWhatsappList(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.total = res.data.total
          this.dataSource = res.data.rows
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
        return str && str.toLocaleLowerCase();
    },
    getQueryParams () {
      const { applyDate, paymentDate, settleDate, endDate } = this.queryParam;
      const data = {
          ...this.queryParam,
          applyDateStart: applyDate && this.transformTime(applyDate[0]),
          applyDateEnd: applyDate && this.transformTime(applyDate[1]),
          paymentDateStart:paymentDate && this.transformTime(paymentDate[0]),
          paymentDateEnd:paymentDate && this.transformTime(paymentDate[1]),
          endDateStart:endDate && this.transformTime(endDate[0]),
          endDateEnd:endDate && this.transformTime(endDate[1]),
          settleDateStart:settleDate && this.transformTime(settleDate[0]),
          settleDateEnd:settleDate && this.transformTime(settleDate[1])
      }
      delete data.applyDate;
      delete data.paymentDate;
      delete data.endDate;
      delete data.settleDate;
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum
      param.pageSize = this.form.pageSize
      return filterObj(param)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    viewCoupon(mode, template= {}) {
      this.visible = true
      this.basicForm.id = template.id
      if (mode == 'edit') {
        var appssids = template.appSsid.split(',');
        this.basicForm = {
          id: template.id,
          name: template.name,
          appSsid: appssids,
          senderId: template.senderId,
          templateName: template.templateName,
          status: template.status
        }
      } else {
        this.basicForm = { ...this.defaultBasicForm }
      }
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.orderNo = ''
      that.queryParam.mobileNo = ''
       that.queryParam.aadhaarName = ''
      that.queryParam.applyDate = undefined;
      that.queryParam.paymentDate = undefined;
      that.queryParam.endDate = undefined;
      that.queryParam.settleDate = undefined;
      that.loadData(this.form.pageNum)
    },
    changeModal (visible) {
        this.visible = visible;
    },
    handleDetail (row) {
        this.orderId = row.id;
        this.custInfoId = row.custInfoId;
        this.changeModal(true);
    },
    handleOk(e) {
      this.ModalText = '';
      this.visibleO = false;
    },
    jumpRepayUrl(row){
      paymentsLink(Object.assign({}, {orderId : row.id})).then((res)=>{
        if(res.code===1000){
            this.ModalText = res.data.url;
            this.visibleO = true;
        }else{
          this.$message.error(res.msg);
        }
      this.loading = false
    }).catch(e => {
        this.$message.error(e.message);
    })
    },
    reductionAmt (record) {
      const self = this;
      var lateFee = record.lateFee;
      this.$confirm({
        title: self.$t('order.isReductionAmt'),
        content: h => {
          return <div>
                  <div>
                      滞纳金：{lateFee}
                  </div>
                  <div>
                      减免金额：<input id = 'repayAmt'></input>
                  </div>
                </div>
        },
        onOk() {
          self.loading = true;

          var repayAmt = document.getElementById("repayAmt").value;

          if(!repayAmt.trim()){
            alert('减免金额不能为空');

            self.loadData();

            return false;
          }

          if(repayAmt > lateFee){
            alert('减免金额不能大于滞纳金');

            self.loadData();

            return false;
          }

          reductionLateFee({ orderId: record.id, reductionAmt: repayAmt }).then(res => {
            self.loadData();
          }).catch(e => {
            self.loading = false;
            self.$message.error(e.message);
          })
        },
      });
    },
  submitForm() {
    this.visible = false
    this.loading = true
    const form = {
      ...this.basicForm
    }

    addWhatsappInfo(form).then(res => {
      this.loading = false
      var code = res.code;
      if(code != 1000){
        this.$message.error(res.msg)
      } else {
        this.$message.success('成功')
      }

      this.loadData(1)
    }).catch(e => {
      this.loading = false
      this.$message.error(e.message)
    })
  },
    handleRepayAll (record) {

      const self = this;
      this.$confirm({

        title: self.$t('order.isSettle') + '（全额）',
        content: h => {
          return <div>
                <div>
                  验证密码：<input id = 'password' type = 'password' />
                </div>
            </div>
        },
        onOk() {
          self.loading = true;
          var password = document.getElementById("password").value;

          if(!password.trim()){
            alert('验证密码不能为空');

            self.loadData();

            return false;
          }

          var md5Password = md5(password);

          repayOfflineAll({ orderId: record.id, password: md5Password}).then(res => {
            if(res.code===1000){
              self.loadData();
            }else{
              self.$message.error(res.msg);
              self.loadData();
            }

          }).catch(e => {
            self.loading = false;
            self.$message.error(e.message);
          })
        },
      });
    },
    extendOrder (record) {

      const self = this;
      this.$confirm({

        title: '确定线下展期还款？',
        content: h => {
          return <div>
          <div>
          验证密码：<input id = 'password' type = 'password' />
            </div>
            </div>
        },
        onOk() {
          self.loading = true;
          var password = document.getElementById("password").value;

          if(!password.trim()){
            alert('验证密码不能为空');

            self.loadData();

            return false;
          }

          var md5Password = md5(password);

          extendOffline({ orderId: record.id, password: md5Password}).then(res => {
            if(res.code===1000){
              self.loadData();
            }else{
              self.$message.error(res.msg);
              self.loadData();
            }

          }).catch(e => {
            self.loading = false;
            self.$message.error(e.message);
          })
        },
      });
    }

  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
