<template>
    <div class="content">
        <a-form layout="inline">
            <a-row :gutter="24">
                <a-col :md="24" :sm="3">
                    <a-form-item label="菜单">
                        <a-select size="large" mode="multiple" v-model="menuIndex" :default-value="[]" style="width: 1000px;"
                            placeholder="Please select" @change="handleChange">
                            <a-select-option v-for="(item, index) in menusArr" :key="index">
                                {{ item.nameCn }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-button type="primary" @click="searchQuery">打开并保存</a-button>
                </a-col>


            </a-row>
        </a-form>
    </div>
</template>
<script>
import moment from "moment";
import { mapGetters, mapActions } from 'vuex'
import { getOtpReportList } from '@/webpublicapi/otpResport';
export default {
    name: 'oneClickOpen',
    data() {
        return {
            menusArr: [],
            menuIndex: []
        }
    },
    computed: {
        ...mapGetters({
            mainMenu: 'menus',

        }),
    },
    created() {
        // console.log(11111111, this.mainMenu)
        this.menusArr = []
        this.mainMenu.forEach(item => {
            if (item.children.length) {
                item.children.forEach(item2 => {
                    this.menusArr.push(item2)
                })
            }
        })
        if( localStorage.getItem('menuIndex')){
            this.menuIndex=JSON.parse(localStorage.getItem('menuIndex'))
        }
    },
    methods: {
        async searchQuery() {
            console.log(2222222, this.menuIndex)
            localStorage.setItem('menuIndex', JSON.stringify(this.menuIndex));
            for (const index of this.menuIndex) {
                await this.$router.push(this.menusArr[index].menuPath);
            }
            // this.menuIndex.forEach(index => {
            //     console.log(index)
            //    this.$router.push(this.menusArr[index].menuPath)
            // })
        },
        handleChange(value) {
            console.log(`selected ${value}`);

        },
    },
    watch: {

    },
}
</script>
<style lang="less" >
@import "~@/assets/less/common.less";

.content {
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 20px
}
// .ant-select-selection__rendered{
//     height: 60px !important;
// }
// .ant-select-selection {
//   height: 600px;
// }
.ant-select-dropdown-menu {
    height: 800px !important;
    max-height: 800px !important;
}

</style>