<template>
  <a-modal
    :title="title"
    :width="600"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="配置key">
          <a-input placeholder="配置key" v-decorator="[ 'configKey', validatorRules.configKey]"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="配置名称">
          <a-input placeholder="配置名称" v-decorator="[ 'configName', validatorRules.configName]"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="注释">
          <a-input placeholder="注释" v-decorator="[ 'configComment', validatorRules.configComment]"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="配置类型">
          <a-select v-model:value="configType"
            v-decorator="[ 'configType', validatorRules.configType]"
          >
            <a-select-option :key="0">手写</a-select-option>
            <a-select-option :key="5">手写(纯数字)</a-select-option>
            <a-select-option :key="10">单选</a-select-option>
            <a-select-option :key="20">多选</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          v-if="configType === 10 || configType === 20 "
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="类型表达式">
          <span style="font-size:12px;color:red">例：[{"n":"选项名称","v":"选项值"},{"n":"选项名称2","v":"选项值2"}]</span>
          <a-input placeholder="类型表达式" v-decorator="[ 'configTypeExpression', validatorRules.configTypeExpression]"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="盘号(-1:全部盘)">
          <a-input placeholder="盘号" v-decorator="[ 'appSsid']"/>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="语言">
          <a-select default-value="" style="width: 120px" v-decorator="[ 'languageMark']">
            <a-select-option value="-1">
              全部
            </a-select-option>
            <a-select-option value="ar">
              阿拉伯语
            </a-select-option>
            <a-select-option value="en">
              英语
            </a-select-option>
            <a-select-option value="es">
              西班牙语
            </a-select-option>
            <a-select-option value="fr">
              法语
            </a-select-option>
            <a-select-option value="sw">
              斯瓦西里语
            </a-select-option>
            <a-select-option value="zh">
              中文
            </a-select-option>
            <a-select-option value="ur">
              乌尔都语
            </a-select-option>
            <a-select-option value="ms">
              马来语
            </a-select-option>
          </a-select>
        </a-form-item>

      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { addSysConfig, editSysConfig } from '@/webpublicapi/importPayoutOrder';
// duplicateCheck
export default {
  name: 'sysConfigModal',

  data () {
    return {
      value: 1,
      title: '操作',
      configType: 0,
      allowPrepay:1,
      visibleCheck: true,
      visible: false,
      model: {},
      appList: [],
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules: {
        configKey: { rules: [{ required: true, message: '请输入配置的key' }] },
        configName: { rules: [{ required: true, message: '请输入配置的名称' }] },
        configType: { rules: [{ required: true, message: '请选择类型' }] },
        configTypeExpression: { rules: [{ required: true, message: '请输入必要表达式' }] },
        configComment: { rules: [{ required: true, message: '请输入配置的注释' }] }
      }

    }
  },
  created () {
  },
  methods: {
    add () {
      this.edit({languageMark: '-1', appSsid: '-1'})
    },
    edit (record) {

      this.form.resetFields()
      this.model = Object.assign({}, record)
      if (record.id) {
        this.allowPrepay = record.allowPrepay
        this.visibleCheck = (record.allowPrepay == 1)
        this.configType = record.configType;
      }
      this.visible = true;
      const data = pick(this.model, 'configKey', 'configName', 'configType', 'configComment', 'configTypeExpression', 'appSsid', 'languageMark');
      data.allowPrepay = !!data.allowPrepay;
      this.$nextTick(() => {
        this.form.setFieldsValue(data);
      })
    },
    onChose (checked) {
      if (checked) {
        this.allowPrepay = 1
        this.visibleCheck = true
      } else {
        this.allowPrepay = 0
        this.visibleCheck = false
      }
    },
    // 确定
    handleOk () {
      const that = this
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true
          const formData = Object.assign(this.model, values)
          formData.allowPrepay = +formData.allowPrepay ? 1 : 0;
          let obj
          if (!that.model.id) {
            formData.id = null;
            obj = addSysConfig(formData)
          } else {
             obj = editSysConfig(formData)
          }
          obj.then((res) => {
            if (res.code === 1000) {
              that.$message.success(res.msg)
              that.$emit('ok');
              that.close();
            } else {
              that.$message.warning(res.msg)
            }
          }).finally(() => {
            that.confirmLoading = false
          })
        }
      })
    },
    // 关闭
    handleCancel () {
      this.close()
    },
    close () {
      this.$emit('close')
      this.visible = false
    }
  }
}
</script>
