<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :md="10" :sm="10">
            <a-form-item :label="$t(I18N_KEY.ORDER + 'loanTime')">
              <a-range-picker
                v-model="queryParam.lendingTime"
                format="YYYY-MM-DD"
                style="width: 100%;"/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="'首付贷类型'">
              <a-select default-value="" style="width: 120px" v-model="queryParam.reborrowFlag">
                <a-select-option value="">
                  全部
                </a-select-option>
                <a-select-option value="0">
                  首贷
                </a-select-option>
                <a-select-option value="1">
                  复贷
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="'产品类型'">
              <a-select
                mode="multiple"
                v-model="queryParam.appssids"
              >
                <a-select-option v-for="item in appList" :key="item.appssid">
                  {{ item.appName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="'总期数'">
              <a-input
                :placeholder="$t('enter') + ' 总期数'"
                v-model="queryParam.totalPeriod"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="'每期天数'">
              <a-input
                :placeholder="$t('enter') + ' 每期天数'"
                v-model="queryParam.duration"></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery('', '')" icon="search" style="margin-left: 8px">{{ $t('search') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :rowClassName="rowClassName"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: 1500}"
        bordered
        @change="handleTableChange">
        <Dict slot="approvalStatus" slot-scope="text" :dict-code="DICT_CODE.ORDER_STATUS">{{ text }}</Dict>
        <div slot="firstOverDueRate" slot-scope="text,record,index">
          {{text}}
          <a-icon  type="arrow-up" style="color: red;" v-if="index < dataSource.length - 1 && new Date(record['curDate']).getTime() <= new Date(currentDate).getTime() - 1000 * 60 *60 * 24 && Number(text.replace('%','')) > Number(dataSource[index+1]['firstOverDueRate'].replace('%',''))" />
          <a-icon type="arrow-down" style="color: green;" v-if="index < dataSource.length - 1 && new Date(record['curDate']).getTime() <= new Date(currentDate).getTime() - 1000 * 60 *60 * 24 && Number(text.replace('%','')) < Number(dataSource[index+1]['firstOverDueRate'].replace('%',''))" />
        </div>
        <div slot="aoverDueRate" slot-scope="text,record,index">
          {{text}}
          <a-icon  type="arrow-up" style="color: red;" v-if="index < dataSource.length - 1 && new Date(record['curDate']).getTime() <= new Date(currentDate).getTime() - 4 * 1000 * 60 *60 * 24 && Number(text.replace('%','')) > Number(dataSource[index+1]['aoverDueRate'].replace('%',''))" />
          <a-icon type="arrow-down" style="color: green;" v-if="index < dataSource.length - 1 && new Date(record['curDate']).getTime() <= new Date(currentDate).getTime() - 4 * 1000 * 60 *60 * 24 && Number(text.replace('%','')) < Number(dataSource[index+1]['aoverDueRate'].replace('%',''))" />
        </div>
        <div slot="firstAmountOverDueRate" :style="new Date(record['curDate']).getTime() <= new Date(currentDate).getTime() - 1000 * 60 *60 * 24 && Number(text.replace('%','')) > Number(record['firstOverDueRate'].replace('%','')) ? {'background':'orange'}:{}" slot-scope="text,record,index">
          {{text}}
          <a-icon  type="arrow-up" style="color: red;" v-if="index < dataSource.length - 1 && new Date(record['curDate']).getTime() <= new Date(currentDate).getTime() - 1000 * 60 *60 * 24 && Number(text.replace('%','')) > Number(dataSource[index+1]['firstAmountOverDueRate'].replace('%',''))" />
          <a-icon type="arrow-down" style="color: green;" v-if="index < dataSource.length - 1 && new Date(record['curDate']).getTime() <= new Date(currentDate).getTime() - 1000 * 60 *60 * 24 && Number(text.replace('%','')) < Number(dataSource[index+1]['firstAmountOverDueRate'].replace('%',''))" />
        </div>
        <div slot="aAmountoverDueRate" slot-scope="text,record,index">
          {{text}}
          <a-icon  type="arrow-up" style="color: red;" v-if="index < dataSource.length - 1 && new Date(record['curDate']).getTime() <= new Date(currentDate).getTime() - 4 * 1000 * 60 *60 * 24 && Number(text.replace('%','')) > Number(dataSource[index+1]['aAmountoverDueRate'].replace('%',''))" />
          <a-icon type="arrow-down" style="color: green;" v-if="index < dataSource.length - 1 && new Date(record['curDate']).getTime() <= new Date(currentDate).getTime() - 4 * 1000 * 60 *60 * 24 && Number(text.replace('%','')) < Number(dataSource[index+1]['aAmountoverDueRate'].replace('%',''))" />
        </div>
      </a-table>

    </div>
  </a-card>
</template>

<script>
import { filterObj } from '@/utils/util'
import {
  countDueReportWithDate,
  getApplist,
  getCountDueReportWithDate,
  getRepaymentReportForInstallment
} from '@/webpublicapi/order'
import { getAllDeadlineConfig } from '@/webpublicapi/deadlineConfig'
import exportFile from '@/webpublicapi/exportFile'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import moment from 'moment'
const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  APPROVAL: 'approval.',
  REPAYREPORT: 'repayReport.',
}
export default {
  name: 'repayReport',
  components: {
    Dict,
    DictSelect,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visible: false,
      exportParams: '',
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      currentDate: 0,
      total: 0,
      appList: [],
      dataSource: [],
      deadlineConfigList: [],
      defalutCol:[
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'curDate'),
          align: 'left',
          fixed: 'left',
          width: 100,
          dataIndex: 'curDate'
        },
        {
          title: '到期量',
          align: 'left',
          dataIndex: 'orderCount'
        },
        {
          title: '一期首逾',
          align: 'left',
          dataIndex: 'firstOverDueRate_1'
        },
        {
          title: '二期首逾',
          align: 'left',
          dataIndex: 'firstOverDueRate_2'
        },
        {
          title: '三期首逾',
          align: 'left',
          dataIndex: 'firstOverDueRate_3'
        },
        {
          title: '一期单量坏账(全量订单口径）',
          align: 'left',
          dataIndex: 'badDebtAllRate_1'
        },
        {
          title: '二期单量坏账(全量订单口径）',
          align: 'left',
          dataIndex: 'badDebtAllRate_2'
        },
        {
          title: '三期单量坏账(全量订单口径）',
          align: 'left',
          dataIndex: 'badDebtAllRate_3'
        },
        {
          title: '一期单量坏账(上期已还口径）',
          align: 'left',
          dataIndex: 'badDebtRate_1'
        },
        {
          title: '二期单量坏账（上期已还口径）',
          align: 'left',
          dataIndex: 'badDebtRate_2'
        },
        {
          title: '三期单量坏账（上期已还口径）',
          align: 'left',
          dataIndex: 'badDebtRate_3',
          width: '100px'
        },
        {
          title: '金额坏账',
          align: 'left',
          dataIndex: 'orderCountUnRate',
          width: '100px'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'extendCountRate'),
          align: 'left',
          dataIndex: 'extendCountRate'
        },
        {
          title: this.$t(I18N_KEY.REPAYREPORT + 'overExtendCountRate'),
          align: 'left',
          dataIndex: 'overExtendCountRate'
        },
      ],
      // 查询条件
      queryParam: {
        "examinerName":"",
        "appssid":"",
        "appssids":undefined,
        // "duration":"",
        "deadlineConfigValues": undefined,
        "examineDate":undefined,
        "period":undefined,
        "totalPeriod":undefined,
        "duration":undefined,
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return this.defalutCol;
    }
  },
  created () {
    this.loadData(1, '', '');
    getApplist().then(res => {
      this.appList = res.data || [];
    });
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery (arg, arg2) {

      this.loadData(1, arg, arg2)
    },
    loadData (arg,arg2,arg3) {
      // 加载数据 若传入参数1则加载第一页的内容
      console.log(arg2)
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams(arg2, arg3)// 查询条件
      this.exportParams = Object.entries(params).map(([key, value]) => key + '=' + value);
      getCountDueReportWithDate(Object.assign({}, params)).then((res)=>{
        var d = new Date() //创建一个Date对象
        this.currentDate = moment((d.getTime() + d.getTimezoneOffset() * 60000) + (3600000 * -5)).format('YYYY-MM-DD')
        if(res.code===1000){
          this.defalutCol = [{
            title: this.$t(I18N_KEY.REPAYREPORT + 'curDate'),
            align: 'left',
            fixed: 'left',
            width: 100,
            dataIndex: 'curDate'
          },
            {
              title: '到期量',
              align: 'left',
              dataIndex: 'orderCount'
            }];
          var columnsppp = res.data.columns;
          for(var i = 0 ; i < columnsppp.length ; i ++) {
            this.defalutCol.push(columnsppp[i]);
          }

          // this.total = res.data && res.data.total
          this.dataSource = res.data.dataList || [];
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    handleAppssids(params) {
      var appssidsValue = params['appssids'];
      if(appssidsValue != null && appssidsValue != '' && appssidsValue != undefined && appssidsValue.length > 0) {
        var apps = appssidsValue[0];
        for(var item in appssidsValue) {
          if(item != '0') {
            apps = apps + '_' + appssidsValue[item];
          }
        }
        params['appssids'] = apps;
      }
      return params;
    },
    handleDeadlineConfigValues(params) {
      var deadlineConfigValues = params['deadlineConfigValues'];
      if(deadlineConfigValues != null && deadlineConfigValues != '' && deadlineConfigValues != undefined && deadlineConfigValues.length > 0) {
        var deadlines = deadlineConfigValues[0];
        for(var item in deadlineConfigValues) {
          if(item != '0') {
            deadlines = deadlines + '_' + deadlineConfigValues[item];
          }
        }
        params['deadlineConfigValues'] = deadlines;
      }
      return params;
    },
    downLoadData () {
      var params = this.getQueryParams()// 查询条件
      params = this.handleAppssids(params);
      params = this.handleDeadlineConfigValues(params);
      this.exportParams = Object.entries(params).map(([key, value]) => key + '=' + value) + '';
      const file = {
        fileUrl: '/api/web/webpublic/statistics/platform/repaymentReportForBeginDateExcel?' + this.exportParams.replaceAll(",", "&"),
        fileName: 'report.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
          this.$message.error(e.message);
      }).finally(() => {
          this.loading = false;
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
       return str && str.toLocaleLowerCase();
    },
    getQueryParams (arg, arg3) {
      const { lendingTime } = this.queryParam;
      const data = {
          ...this.queryParam,
        lendingTimeStart: (lendingTime && lendingTime.length) ? this.transformTime(lendingTime[0]) : undefined,
          lendingTimeEnd : (lendingTime && lendingTime.length) ? this.transformTime(lendingTime[1]) : undefined,
      }
      delete data.lendingTime;
      return filterObj(data)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.examinerName = ''
      that.queryParam.examineDate = undefined;
      that.loadData(this.form.pageNum)
    },
    rowClassName(record,index){
      var date = new Date(record.curDate);
      var day = date.getTime() / 24 / 60 / 60 / 1000 + 3;
      console.log(record.curDate , date, (day % 7) + 1);

      if((day % 7) + 1 == 7){
        return 'table_weekendColor';
      }
      return '';
    }
  }
}
</script>

<style lang="less" scoped>
 @import "~@/assets/less/common.less";

</style>
