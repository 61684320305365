import { axios } from '@/utils/request'
export function specialPayChannelOrderList(data = {}) {
    return axios({
        url: '/specialPayChannelOrder/list',
        method: 'post',
        data
    })
  }
  export function specialPayChannelOrderDelete(data = {}) {
    return axios({
        url: 'specialPayChannelOrder/delete',
        method: 'post',
        data
    })
  }
