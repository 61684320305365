<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <div class="table-operator"  style="margin-top: 5px">
        <a-button @click="flushPage" type="primary" icon="plus">{{ $t('flush') }}</a-button>
      </div>
      <a-table
        ref="table"
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered>
        <span slot="action" slot-scope="text, record">
        <a @click="handleEdit(record)">{{ $t('edit') }}</a>
      </span>
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
      </div>
      <assign-config-edit-new ref="modalForm" :tele-market-users='teleMarketUsers' :auto-assign-types='autoAssignTypes' :apps='apps' :types='types' @ok="modalFormOk"></assign-config-edit-new>
    </div>
  </div>
</template>
<script>
import {
  baseData,
  assignConfigEditNew
} from '@/webpublicapi/teleMarket'
import AssignConfigEditNew from './modules/AssignConfigEditNew'
import moment from "moment";
import {mapGetters} from "vuex";
const I18N_KEY = 'teleMarket.';
export default {
  name: 'TeleMarketAssignConfig',
  components: {
    AssignConfigEditNew,
  },
  data () {
    return {
      I18N_KEY,
      loading: false,
      total: 0,
      teleMarketUsers: [],
      types: [],
      autoAssignTypes: [],
      apps: [],
      fieldName: 'textCn',
      // createdTime: [],
      // queryParam: {
      //   pageNum: 1,
      //   pageSize: 10,
      //   type: null,
        // startDate: null,
        // endDate: null,
      // },
      visible: false,
      dataSource: [],
    }
  },
  computed: {
    ...mapGetters('language', ['languageStorage']),
    // 表头
    columns () {
      return [
        {
          title: this.$t(I18N_KEY + 'type'),
          align: 'center',
          dataIndex: 'type',
          customRender: (text, record) => {
            var text = text;
            if(text != undefined && text != null && text != '') {
              for(var i in this.types) {
                if(text === this.types[i].value) {
                  return this.types[i][this.fieldName]
                }
              }
            } else {
              return '';
            }
          },
        },
        {
          title: this.$t(I18N_KEY + 'param'),
          align:"center",
          dataIndex: 'param',
        },
        {
          title: this.$t(I18N_KEY + 'teleMarketUser'),
          align:"center",
          dataIndex: 'user',
          customRender: (text, record) => {
            var text = text;
            var result = "";
            if(text != undefined && text != null && text != '') {
              var users = text.split(',')
              for(var i in users) {
                var user = users[i]
                for(var x in this.teleMarketUsers) {
                  if(user === this.teleMarketUsers[x].value) {
                    result = result + this.teleMarketUsers[x].label + ','
                    break
                  }
                }
              }
            }
            if(result.endsWith(',')) {
              result = result.substring(0, result.length-1)
            }
            return result;
          }
        },
        {
          title: this.$t(I18N_KEY + 'startTime'),
          align:"center",
          dataIndex: 'startTime',
        },
        {
          title: this.$t(I18N_KEY + 'endTime'),
          align:"center",
          dataIndex: 'endTime',
        },
        {
          title: this.$t(I18N_KEY + 'sort'),
          align:"center",
          dataIndex: 'sort',
        },
        {
          title: this.$t(I18N_KEY + 'operate'),
          dataIndex: 'action',
          align:"center",
          scopedSlots: { customRender: 'action' },
        }]
    },
  },
  created () {
    this.loadBaseData();
  },
  methods: {
    loadBaseData(){
      this.loading = true;
      baseData({}).then((res) => {
        if (res.code === 1000) {
          this.types = res.data.types
          this.teleMarketUsers = res.data.systemSeats
          this.autoAssignTypes = res.data.autoAssignTypes
          this.apps = res.data.apps
          this.dataSource = res.data.typeConfigs
        } else {
          this.$message.error(res.msg)
        }
        this.loading = false;
      }).catch(e => {
        this.$message.error(e.message);
        this.loading = false;
      })
    },
    searchQuery () {
      this.loadBaseData();
    },
    flushPage() {
      this.loadBaseData();
    },
    modalFormOk(data) {
      this.createSeat(data);
    },
    // handleAdd () {
    //   this.versionId = undefined;
    //   this.$refs.modalForm.add();
    //   this.$refs.modalForm.title = "1";
    //   this.$refs.modalForm.disableSubmit = false;
    // },
    handleEdit (record) {
      this.type = record.type;
      this.$refs.modalForm.edit(record);
      this.$refs.modalForm.title = "2";
      this.$refs.modalForm.disableSubmit = false;
    },
    changeModal (visible) {
      this.visible = visible;
    },
    // deleteCurrent (row) {
    //   this.loading = true;
    //   assignConfigDelete(row.id).then(res => {
    //     this.$message.success(this.$t('success'));
    //     this.loadData(1);
    //   }).catch(e => {
    //     this.loading = false;
    //     this.$message.error(e.message);
    //   });
    // },
    createSeat (data) {
      console.log(data)
      const form = {
        ...data,
        type: this.type,
      };
      console.log(form)
      // const requestField = this.versionId ? '2' : '1'
      assignConfigEditNew(form).then(res => {
        this.$refs.modalForm.closeLoading();
        this.$refs.modalForm.close();
        if (res.code === 1000) {
          this.$message.success(res.msg);
          this.loadBaseData();
        } else {
          this.$message.error(res.msg);
        }
      }).catch(e => {
        this.$refs.modalForm.closeLoading();
        this.$message.error(e.message);
      })
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    searchReset () {
      this.createdTime = [],
        this.queryParam.pageNum=1
      this.queryParam.pageSize=10
      this.queryParam.type=null
      // this.queryParam.startDate=null
      // this.queryParam.endDate=null
    },
    // loadData (pageNum) {
    //   if(pageNum) { this.queryParam.pageNum = pageNum; }
    //   this.loading = true;
    //   assignConfigList(this.queryParam).then(res => {
    //     this.loading = false;
    //     this.total = res.data.total
    //     this.dataSource = res.data.rows;
    //   }).catch(e => {
    //     this.loading = false;
    //     this.$message.error(e.message);
    //   });
    // }
  },
  watch: {
    languageStorage: {
      handler (lang) {
        if (lang == 'en-US') {
          this.fieldName = 'textEn';
        } else if (lang == 'zh-CN') {
          this.fieldName = 'textCn';
        } else if (lang == 'es') {
          this.fieldName = 'textEs';
        } else {
          this.fieldName = 'textEn';
        }
      },
      immediate: true,
    }
  },
}
</script>
<style scoped>
@import '~@/assets/less/common.less';
</style>
